
.btn-primary-color {
  padding: 8px 24px 8px 24px;
  border-radius: 5px;
  background: #033A9A;
}

.btn-contact-us {
  padding: 12px 24px 12px 24px;
  border-radius: 5px;
  background-color: #033A9A;
}
