//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------


/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');


.font-family-play {
  font-family: "Play", sans-serif;
}


.font-color-primary-default {
  color: #033A9A;
}

.font-color-neutral-grey-lightest {
  color: #CECDCC;
}

.font-color-neutral-grey-light {
  color: #848280;
}

.font-color-neutral-grey-dark {
  color: #5C5855;
}

.font-color-neutral-grey-darkest {
  color: #332F2A;
}

.font-color-neutral-grey-darker {
  color: #686868;
}

.font-color-primary-100{
  color: #010C1F;
}

.font-color-primary-30{
  color: #819CCC;
}

.font-color-dark-green {
  color: #258E3F;
}

.font-color-neutral-dark {
  color: #1D1D1D;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

@include media-breakpoint-down(sm) {
  .font-size-18 {
    font-size: 16px;
  }
}

.font-size-28 {
  font-size: 28px;
}

.font-size-24 {
  font-size: 24px;
}

@include media-breakpoint-down(sm) {
  .font-size-24 {
    font-size: 18px;
  }
}

.font-size-32 {
  font-size: 32px;
}

.font-size-42 {
  font-size: 42px;
}

@include media-breakpoint-down(sm) {
  .font-size-42 {
    font-size: 24px;
  }
}

.font-size-48 {
  font-size: 48px;
}

@include media-breakpoint-down(sm) {
  .font-size-48 {
    font-size: 32px;
  }
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}
