/* Bar Color */
#nav, .nav-tabs, .nav-item a{
  background-color: #F5F6F6;
}
//
//#nav {
//  //border-top: 1px solid #E5E8E7;
//  border-bottom: 1px solid #E5E8E7;
//}


body ,html{
  overflow-x: hidden;
  box-sizing: border-box;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
}


.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding: 8px 16px 8px 16px !important;
  gap: 8px !important;
  border: none !important;
  color: #424847 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #FF8600 !important;
  padding: 8px 16px 8px 16px !important;
  gap: 8px !important;
  background-color: #FFFFFF !important;
  border-top: 2px solid #FF8600 !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #E5E8E7 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

//.nav .nav-pills, .nav-link.show .nav-link {
//  background-color: transparent;
//}
//
//.nav .nav-link.active, .nav-pills .nav-item.show .nav-link {
//  background-color: #FFFFFF;
//}

.border-color-grey-1 {
  border-color: #C0C0C0;
}

@include media-breakpoint-up(md) {
  .our-services-details-container {
    height: 80vh;
    overflow-y: scroll;
  }

  .our-services-details-left-container {
    height: 80vh;
    position: sticky;
    top: 0; /* Stick to the top of the container */
    flex-shrink: 0;
  }

  .our-services-details-container::-webkit-scrollbar {
    display: none;
  }

}

.bg-shadow-why-choose-details-container {
  box-shadow: 0px 0px 16px 0px #CDD8EB;
}


