.bg-body-background-color {
  background: #E9EEF6;
}

.bg-footer-color {
  background: #020F26;
}

.bg-background-light-sky-color {
  background: #F1F2FB;
}

.bg-navbar-color {
  background: #F1F2FB;
}

.bg-primary-default {
  background-color: #033A9A;
}


.bg-hero-section-container {
  background-image: url("../../../../public/images/landing/bg-hero-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
  //height: 80vh;
}

.bg-cta-container {
  background-image: url("../../../../public/images/landing/bg-cta-section-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
}

.bg-btn-shadow {
  box-shadow: 0px 10px 40px 0px #0000000D;
}

.bg-light-green {
  background: #D7F4DE;
}

.bg-light-red {
  background: #FFCFCC;
}
